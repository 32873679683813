// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Units = require("./Units.bs.js");
var Particles = require("../Styles/Particles.bs.js");
var Responsive = require("./Responsive.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var View = require("../../helpers/View").default;

function $$eval(x) {
  if (typeof x !== "object") {
    if (x === "none") {
      return Particles.Space.toString(Particles.Space.none);
    } else if (x === "zero") {
      return "0px";
    } else if (x === "lg") {
      return Particles.Space.toString(Particles.Space.l);
    } else if (x === "md") {
      return Particles.Space.toString(Particles.Space.m);
    } else if (x === "sm") {
      return Particles.Space.toString(Particles.Space.s);
    } else if (x === "xl") {
      return Particles.Space.toString(Particles.Space.xl);
    } else if (x === "xs") {
      return Particles.Space.toString(Particles.Space.xs);
    } else if (x === "xxl") {
      return Particles.Space.toString(Particles.Space.xxl);
    } else {
      return "auto";
    }
  }
  var variant = x.NAME;
  if (variant === "px") {
    return x.VAL.toString() + "px";
  } else if (variant === "rem") {
    return x.VAL.toString() + "rem";
  } else {
    return Responsive.mapResponsive($$eval, x);
  }
}

function map(t) {
  return Core__Option.map(t, $$eval);
}

var Space = {
  $$eval: $$eval,
  map: map
};

function $$eval$1(x) {
  if (typeof x !== "object") {
    if (x === "initial") {
      return "initial";
    } else if (x === "inherit_") {
      return "inherit";
    } else {
      return "auto";
    }
  }
  var variant = x.NAME;
  if (variant === "percent") {
    return x.VAL.toString() + "%";
  } else if (variant === "px") {
    return x.VAL.toString() + "px";
  } else if (variant === "rem") {
    return x.VAL.toString() + "rem";
  } else {
    return Responsive.mapResponsive($$eval$1, x);
  }
}

var Width = {
  $$eval: $$eval$1
};

function $$eval$2(x) {
  if (typeof x !== "object") {
    if (x === "initial") {
      return "initial";
    } else if (x === "inherit_") {
      return "inherit";
    } else {
      return "none";
    }
  }
  var variant = x.NAME;
  if (variant === "percent") {
    return x.VAL.toString() + "%";
  } else if (variant === "px") {
    return x.VAL.toString() + "px";
  } else if (variant === "rem") {
    return x.VAL.toString() + "rem";
  } else {
    return Responsive.mapResponsive($$eval$2, x);
  }
}

var MinMaxWidthHeight = {
  $$eval: $$eval$2
};

function $$eval$3(x) {
  if (typeof x === "object") {
    return Responsive.mapResponsive($$eval$3, x);
  } else if (x === "stretch") {
    return "stretch";
  } else if (x === "center") {
    return "center";
  } else if (x === "baseline") {
    return "baseline";
  } else if (x === "flexStart") {
    return "flex-start";
  } else if (x === "flexEnd") {
    return "flex-end";
  } else {
    return "auto";
  }
}

function map$1(t) {
  return Core__Option.map(t, $$eval$3);
}

var Align = {
  $$eval: $$eval$3,
  map: map$1
};

function $$eval$4(x) {
  if (typeof x === "object") {
    return Responsive.mapResponsive($$eval$4, x);
  } else if (x === "spaceAround") {
    return "space-around";
  } else if (x === "center") {
    return "center";
  } else if (x === "spaceBetween") {
    return "space-between";
  } else if (x === "flexStart") {
    return "flex-start";
  } else if (x === "flexEnd") {
    return "flex-end";
  } else {
    return "space-evenly";
  }
}

function map$2(t) {
  return Core__Option.map(t, $$eval$4);
}

var Justify = {
  $$eval: $$eval$4,
  map: map$2
};

function $$eval$5(x) {
  if (typeof x === "object") {
    return Responsive.mapResponsive($$eval$5, x);
  } else if (x === "row") {
    return "row";
  } else if (x === "columnReverse") {
    return "column-reverse";
  } else if (x === "rowReverse") {
    return "row-reverse";
  } else {
    return "column";
  }
}

function map$3(t) {
  return Core__Option.map(t, $$eval$5);
}

var FlexDirection = {
  $$eval: $$eval$5,
  map: map$3
};

function $$eval$6(x) {
  if (typeof x !== "object") {
    if (x === "zero") {
      return "0";
    } else {
      return "auto";
    }
  }
  var variant = x.NAME;
  if (variant === "percent") {
    return x.VAL.toString() + "%";
  } else if (variant === "px") {
    return String(x.VAL) + "px";
  } else {
    return Responsive.mapResponsive($$eval$6, x);
  }
}

function map$4(t) {
  return Core__Option.map(t, $$eval$6);
}

var FlexBasis = {
  $$eval: $$eval$6,
  map: map$4
};

function $$eval$7(x) {
  if (x === "wrapReverse") {
    return "wrap-reverse";
  } else if (x === "noWrap") {
    return "nowrap";
  } else {
    return "wrap";
  }
}

function map$5(t) {
  return Core__Option.map(t, $$eval$7);
}

var FlexWrap = {
  $$eval: $$eval$7,
  map: map$5
};

var make = View;

var JS = {
  make: make
};

function View__Jsx3$View(props) {
  return JsxRuntime.jsx(make, {
              id: props.id,
              m: Core__Option.map(props.m, $$eval),
              mt: Core__Option.map(props.mt, $$eval),
              mr: Core__Option.map(props.mr, $$eval),
              mb: Core__Option.map(props.mb, $$eval),
              ml: Core__Option.map(props.ml, $$eval),
              mx: Core__Option.map(props.mx, $$eval),
              my: Core__Option.map(props.my, $$eval),
              p: Core__Option.map(props.p, $$eval),
              pt: Core__Option.map(props.pt, $$eval),
              pr: Core__Option.map(props.pr, $$eval),
              pb: Core__Option.map(props.pb, $$eval),
              pl: Core__Option.map(props.pl, $$eval),
              px: Core__Option.map(props.px, $$eval),
              py: Core__Option.map(props.py, $$eval),
              width: Core__Option.map(props.width, $$eval$1),
              minWidth: Core__Option.map(props.minWidth, $$eval$2),
              maxWidth: Core__Option.map(props.maxWidth, $$eval$2),
              height: Core__Option.map(props.height, $$eval$1),
              minHeight: Core__Option.map(props.minHeight, $$eval$2),
              maxHeight: Core__Option.map(props.maxHeight, $$eval$2),
              alignItems: Core__Option.map(props.alignItems, $$eval$3),
              justifyContent: Core__Option.map(props.justifyContent, $$eval$4),
              flexDirection: Core__Option.map(props.flexDirection, $$eval$5),
              flexWrap: Core__Option.map(props.flexWrap, $$eval$7),
              flexGrow: props.flexGrow,
              flexShrink: props.flexShrink,
              className: props.className,
              flexBasis: Core__Option.map(props.flexBasis, $$eval$6),
              background: props.background,
              backgroundColor: Core__Option.map(props.backgroundColor, Units.hex_to_string),
              borderWidth: props.borderWidth,
              borderStyle: Core__Option.map(props.borderStyle, (function (x) {
                      if (x === "initial") {
                        return "initial";
                      } else if (x === "hidden") {
                        return "hidden";
                      } else if (x === "inherit_") {
                        return "inherit";
                      } else if (x === "ridge") {
                        return "ridge";
                      } else if (x === "solid") {
                        return "solid";
                      } else if (x === "outset") {
                        return "outset";
                      } else if (x === "groove") {
                        return "groove";
                      } else if (x === "dashed") {
                        return "dashed";
                      } else if (x === "dotted") {
                        return "dotted";
                      } else if (x === "double") {
                        return "double";
                      } else if (x === "inset") {
                        return "inset";
                      } else {
                        return "none";
                      }
                    })),
              borderRadius: props.borderRadius,
              borderColor: props.borderColor,
              boxShadow: props.boxShadow,
              position: Core__Option.map(props.position, (function (x) {
                      if (x === "initial") {
                        return "initial";
                      } else if (x === "inherit_") {
                        return "inherit";
                      } else if (x === "fixed") {
                        return "fixed";
                      } else if (x === "static") {
                        return "static";
                      } else if (x === "sticky") {
                        return "sticky";
                      } else if (x === "relative") {
                        return "relative";
                      } else {
                        return "absolute";
                      }
                    })),
              zIndex: props.zIndex,
              overflow: Core__Option.map(props.overflow, (function (x) {
                      if (x === "scroll") {
                        return "scroll";
                      } else if (x === "initial") {
                        return "initial";
                      } else if (x === "hidden") {
                        return "hidden";
                      } else if (x === "inherit_") {
                        return "inherit";
                      } else if (x === "visible") {
                        return "visible";
                      } else {
                        return "auto";
                      }
                    })),
              children: props.children
            });
}

var View$1 = {
  JS: JS,
  make: View__Jsx3$View
};

var Responsive$1;

var Height;

var make$1 = View__Jsx3$View;

exports.Responsive = Responsive$1;
exports.Space = Space;
exports.Width = Width;
exports.Height = Height;
exports.MinMaxWidthHeight = MinMaxWidthHeight;
exports.Align = Align;
exports.Justify = Justify;
exports.FlexDirection = FlexDirection;
exports.FlexBasis = FlexBasis;
exports.FlexWrap = FlexWrap;
exports.View = View$1;
exports.make = make$1;
/* make Not a pure module */
