// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

function _responsive(xs, sm, md, lg, xl, xxl, param) {
  return {
          xs: xs,
          sm: sm,
          md: md,
          lg: lg,
          xl: xl,
          xxl: xxl
        };
}

function mapResponsive(f, x) {
  var match = x.VAL;
  return _responsive(Core__Option.map(match[0], f), Core__Option.map(match[1], f), Core__Option.map(match[2], f), Core__Option.map(match[3], f), Core__Option.map(match[4], f), Core__Option.map(match[5], f), undefined);
}

function make(xs, sm, md, lg, xl, xxl, param) {
  return {
          NAME: "responsive",
          VAL: [
            xs,
            sm,
            md,
            lg,
            xl,
            xxl
          ]
        };
}

exports._responsive = _responsive;
exports.mapResponsive = mapResponsive;
exports.make = make;
/* No side effect */
