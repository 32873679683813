// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__JSON = require("@rescript/core/src/Core__JSON.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");

function now() {
  return new Date(Date.now());
}

function decode(str) {
  var date = new Date(str);
  if (date.toString() === "Invalid Date") {
    return {
            TAG: "Error",
            _0: str + " is not a valid date"
          };
  } else {
    return {
            TAG: "Ok",
            _0: date
          };
  }
}

function decodeFromFloat(flt) {
  var date = new Date(flt);
  if (date.toString() === "Invalid Date") {
    return {
            TAG: "Error",
            _0: flt.toString() + " is not a valid float date"
          };
  } else {
    return {
            TAG: "Ok",
            _0: date
          };
  }
}

function fromFloat(json) {
  return Core__Result.flatMap((function (x) {
                  if (x !== undefined) {
                    return {
                            TAG: "Ok",
                            _0: x
                          };
                  } else {
                    return {
                            TAG: "Error",
                            _0: "Failed to decode json"
                          };
                  }
                })(Core__JSON.Decode.$$float(json)), decodeFromFloat);
}

function encodeAsISOString(prim) {
  return prim.toISOString();
}

function encodeAsString(prim) {
  return prim.toString();
}

function encodeAsFloat(prim) {
  return prim.getTime();
}

exports.now = now;
exports.decode = decode;
exports.decodeFromFloat = decodeFromFloat;
exports.fromFloat = fromFloat;
exports.encodeAsISOString = encodeAsISOString;
exports.encodeAsString = encodeAsString;
exports.encodeAsFloat = encodeAsFloat;
/* No side effect */
