// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");

var Chapter = {};

var Feature = {};

function isFree(x) {
  if (x.type_ === "FREE") {
    return true;
  } else {
    return false;
  }
}

function isTrial(x) {
  if (x.handle === "PRO_TRIAL") {
    return true;
  } else {
    return false;
  }
}

function hasFeature(plan, feature) {
  return Core__Option.isSome(Core__List.find(plan.features, (function (enabledFeature) {
                    return enabledFeature === feature;
                  })));
}

var Plan_defaultPlan = {
  type_: "FREE",
  handle: "FREE",
  onProFree: false,
  features: /* [] */0
};

var Plan = {
  Feature: Feature,
  defaultPlan: Plan_defaultPlan,
  isFree: isFree,
  isTrial: isTrial,
  hasFeature: hasFeature
};

var Permission = {};

var Agent = {};

var Client = {};

exports.Chapter = Chapter;
exports.Plan = Plan;
exports.Permission = Permission;
exports.Agent = Agent;
exports.Client = Client;
/* No side effect */
