// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Next__Head = require("../next/Next__Head.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Webapi__Dom__Document = require("rescript-webapi/src/Webapi/Dom/Webapi__Dom__Document.bs.js");

function setTitle(title) {
  Core__Option.forEach(Webapi__Dom__Document.asHtmlDocument(document), (function (a) {
          a.title = title;
        }));
}

function usePageTitle(title) {
  React.useEffect((function () {
          setTitle(title);
          return (function () {
                    setTitle("TAN: The only online network built exclusively for verified top agents");
                  });
        }), [title]);
}

function PageTitle(props) {
  var title = props.title;
  return JsxRuntime.jsxs(Next__Head.make, {
              children: [
                JsxRuntime.jsx("title", {
                      children: title
                    }),
                JsxRuntime.jsx("meta", {
                      content: title,
                      property: "og:title"
                    }, "title")
              ]
            });
}

var make = PageTitle;

var $$default = PageTitle;

exports.setTitle = setTitle;
exports.usePageTitle = usePageTitle;
exports.make = make;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
