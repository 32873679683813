// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"membership_account_owner_agent":{"f":""},"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function accountMemberRole_decode($$enum) {
  if ($$enum === "TRANSACTION_COORDINATOR" || $$enum === "SHOWING_ASSISTANT" || $$enum === "PARTNER" || $$enum === "OWNER" || $$enum === "OPERATIONS_MANAGER" || $$enum === "MARKETING_SPECIALIST" || $$enum === "LISTING_SPECIALIST" || $$enum === "LISTING_MANAGER" || $$enum === "INSIDE_SALES_AGENT" || $$enum === "CLIENT_CARE_COORDINATOR" || $$enum === "BUYER_SPECIALIST" || $$enum === "ASSOCIATE" || $$enum === "ASSISTANT" || $$enum === "UNKNOWN") {
    return $$enum;
  }
  
}

function accountMemberRole_fromString(str) {
  return accountMemberRole_decode(str);
}

function accountStatusV5_decode($$enum) {
  if ($$enum === "ACTIVE" || $$enum === "TERMINATED") {
    return $$enum;
  }
  
}

function accountStatusV5_fromString(str) {
  return accountStatusV5_decode(str);
}

function agentPermissions_decode($$enum) {
  if ($$enum === "MEMBERSHIP_REQUESTS" || $$enum === "SWITCH_CHAPTER") {
    return $$enum;
  }
  
}

function agentPermissions_fromString(str) {
  return agentPermissions_decode(str);
}

function onOrOffV5_decode($$enum) {
  if ($$enum === "OFF" || $$enum === "ON") {
    return $$enum;
  }
  
}

function onOrOffV5_fromString(str) {
  return onOrOffV5_decode(str);
}

function planFeature_decode($$enum) {
  if ($$enum === "SHARE_WITH_CLIENT" || $$enum === "PROPERTY_DETAILS" || $$enum === "INITIATE_PROPERTY_CONVERSATION" || $$enum === "HIDE_PROPERTY" || $$enum === "GNS" || $$enum === "FULL_AGENT_PAGE" || $$enum === "FORWARD_PROPERTY_EMAIL" || $$enum === "CLIENT_ACCESS" || $$enum === "VIEWING_AGENT") {
    return $$enum;
  }
  
}

function planFeature_fromString(str) {
  return planFeature_decode(str);
}

function planHandleV5_decode($$enum) {
  if ($$enum === "PRO_TRIAL" || $$enum === "PRO_QUARTERLY" || $$enum === "PRO_MONTHLY" || $$enum === "PRO_FREE" || $$enum === "FREE" || $$enum === "COMPLIMENTARY" || $$enum === "PRO_YEARLY") {
    return $$enum;
  }
  
}

function planHandleV5_fromString(str) {
  return planHandleV5_decode(str);
}

function planType_decode($$enum) {
  if ($$enum === "PRO" || $$enum === "FREE" || $$enum === "COMPLIMENTARY" || $$enum === "PRO_ONLY") {
    return $$enum;
  }
  
}

function planType_fromString(str) {
  return planType_decode(str);
}

var Utils = {
  accountMemberRole_decode: accountMemberRole_decode,
  accountMemberRole_fromString: accountMemberRole_fromString,
  accountStatusV5_decode: accountStatusV5_decode,
  accountStatusV5_fromString: accountStatusV5_fromString,
  agentPermissions_decode: agentPermissions_decode,
  agentPermissions_fromString: agentPermissions_fromString,
  onOrOffV5_decode: onOrOffV5_decode,
  onOrOffV5_fromString: onOrOffV5_fromString,
  planFeature_decode: planFeature_decode,
  planFeature_fromString: planFeature_fromString,
  planHandleV5_decode: planHandleV5_decode,
  planHandleV5_fromString: planHandleV5_fromString,
  planType_decode: planType_decode,
  planType_fromString: planType_fromString
};

var node = ((function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CurrentUserContextQueryRelayLegacyUser_agent"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentUserContextQueryRelay_agent",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "AccountMember",
        "kind": "LinkedField",
        "name": "membership",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            "action": "THROW"
          },
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "AccountV5",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountStatus",
                    "storageKey": null
                  },
                  "action": "THROW"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountMember",
                  "kind": "LinkedField",
                  "name": "owner",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Agent",
                      "kind": "LinkedField",
                      "name": "agent",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": "subscription_",
                    "args": null,
                    "concreteType": "AccountSubscriptionV5",
                    "kind": "LinkedField",
                    "name": "subscription",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "kind": "RequiredField",
                        "field": {
                          "alias": null,
                          "args": null,
                          "concreteType": "SubscriptionPlanV5",
                          "kind": "LinkedField",
                          "name": "plan",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "features",
                              "storageKey": null
                            },
                            {
                              "kind": "RequiredField",
                              "field": {
                                "alias": "type_",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              "action": "THROW"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "handle",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        "action": "THROW"
                      }
                    ],
                    "storageKey": null
                  },
                  "action": "THROW"
                }
              ],
              "storageKey": null
            },
            "action": "THROW"
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tanStaff",
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "ChapterV5",
        "kind": "LinkedField",
        "name": "defaultChapter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "databaseId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ChapterPreferencesV5",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "granularNotificationSettings",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "improvedLocations",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mlsComingSoon",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "payingChapter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "anonymousMatching",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hideChapterStats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultToChapterPreferenceAreas",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timezone",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "type": "Agent",
  "abstractKey": null
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
