// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRelay = require("react-relay");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RescriptRelay_Internal = require("rescript-relay/src/RescriptRelay_Internal.bs.js");

function makeRefetchVariables() {
  
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

function unwrap_response_sessionV5_authenticatedUser(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "Agent",
              "ClientAccountV5"
            ]);
}

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {"__root":{"sessionV5_authenticatedUser_ClientAccountV5":{"f":""},"sessionV5_authenticatedUser_Agent":{"f":""},"sessionV5_authenticatedUser":{"u":"response_sessionV5_authenticatedUser"}}};

var wrapResponseConverterMap = {
  response_sessionV5_authenticatedUser: RescriptRelay_Internal.wrapUnion
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"sessionV5_authenticatedUser_ClientAccountV5":{"f":""},"sessionV5_authenticatedUser_Agent":{"f":""},"sessionV5_authenticatedUser":{"u":"response_sessionV5_authenticatedUser"}}};

var responseConverterMap = {
  response_sessionV5_authenticatedUser: unwrap_response_sessionV5_authenticatedUser
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAuthenticated",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isImpersonated",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "brokerage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
},
v9 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "ProfilePhoto",
  "kind": "LinkedField",
  "name": "profilePhoto",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "membershipStatus",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "databaseId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "AccountV5",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Agent",
  "kind": "LinkedField",
  "name": "agent",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v15/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUserContextQueryRelayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionV5",
        "kind": "LinkedField",
        "name": "sessionV5",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "authenticatedUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CurrentUserContextQueryRelay_agent"
                  }
                ],
                "type": "Agent",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CurrentUserContextQueryRelay_clientAccount"
                  }
                ],
                "type": "ClientAccountV5",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "RequiredField",
            "field": (v1/*: any*/),
            "action": "NONE"
          },
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "NONE"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrentUserContextQueryRelayQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionV5",
        "kind": "LinkedField",
        "name": "sessionV5",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "authenticatedUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountMember",
                    "kind": "LinkedField",
                    "name": "membership",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountV5",
                        "kind": "LinkedField",
                        "name": "account",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accountStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountMember",
                            "kind": "LinkedField",
                            "name": "owner",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v16/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "subscription_",
                            "args": null,
                            "concreteType": "AccountSubscriptionV5",
                            "kind": "LinkedField",
                            "name": "subscription",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SubscriptionPlanV5",
                                "kind": "LinkedField",
                                "name": "plan",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "features",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": "type_",
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "type",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "handle",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tanStaff",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ChapterV5",
                    "kind": "LinkedField",
                    "name": "defaultChapter",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChapterPreferencesV5",
                        "kind": "LinkedField",
                        "name": "preferences",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "granularNotificationSettings",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "improvedLocations",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mlsComingSoon",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payingChapter",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "anonymousMatching",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hideChapterStats",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "defaultToChapterPreferenceAreas",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timezone",
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "permissions",
                    "storageKey": null
                  }
                ],
                "type": "Agent",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": "clientEmail",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProfilePhoto",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CapAgentBuyerNeed",
                    "kind": "LinkedField",
                    "name": "agentBuyerNeed",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ClientAccountV5",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v14/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "35e7c0e496fbf81e0237126aa081a5e2",
    "id": null,
    "metadata": {},
    "name": "CurrentUserContextQueryRelayQuery",
    "operationKind": "query",
    "text": "query CurrentUserContextQueryRelayQuery {\n  sessionV5 {\n    authenticatedUser {\n      __typename\n      ... on Agent {\n        ...CurrentUserContextQueryRelay_agent\n      }\n      ... on ClientAccountV5 {\n        ...CurrentUserContextQueryRelay_clientAccount\n      }\n      ... on Node {\n        __isNode: __typename\n        __typename\n        nodeId\n      }\n    }\n    isAuthenticated\n    isImpersonated\n  }\n}\n\nfragment CurrentUserContextQueryRelayLegacyUser_agent on Agent {\n  id\n  firstName\n  lastName\n  displayName\n  brokerage\n  emailAddress\n  profilePhoto {\n    id\n    key\n  }\n  phone\n  membershipStatus\n  account {\n    databaseId\n    nodeId\n  }\n}\n\nfragment CurrentUserContextQueryRelay_agent on Agent {\n  ...CurrentUserContextQueryRelayLegacyUser_agent\n  membership {\n    role\n    account {\n      accountStatus\n      owner {\n        id\n        agent {\n          ...CurrentUserContextQueryRelayLegacyUser_agent\n          nodeId\n        }\n        nodeId\n      }\n      subscription_: subscription {\n        id\n        plan {\n          id\n          features\n          type_: type\n          handle\n        }\n      }\n      nodeId\n    }\n    nodeId\n  }\n  tanStaff\n  defaultChapter {\n    databaseId\n    displayName\n    preferences {\n      granularNotificationSettings\n      improvedLocations\n      mlsComingSoon\n      payingChapter\n      anonymousMatching\n      hideChapterStats\n      defaultToChapterPreferenceAreas\n    }\n    timezone\n    nodeId\n  }\n  permissions\n}\n\nfragment CurrentUserContextQueryRelay_clientAccount on ClientAccountV5 {\n  id\n  firstName\n  lastName\n  clientEmail: email\n  status\n  avatar {\n    id\n    key\n  }\n  agent {\n    ...CurrentUserContextQueryRelayLegacyUser_agent\n    nodeId\n  }\n  agentBuyerNeed {\n    id\n  }\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

var wrap_response_sessionV5_authenticatedUser = RescriptRelay_Internal.wrapUnion;

exports.Types = Types;
exports.unwrap_response_sessionV5_authenticatedUser = unwrap_response_sessionV5_authenticatedUser;
exports.wrap_response_sessionV5_authenticatedUser = wrap_response_sessionV5_authenticatedUser;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
