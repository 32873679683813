import React from 'react'

import { Provider as ReduxProvider } from 'react-redux'
import { IntercomProvider } from 'react-use-intercom'
import CurrentUserProvider from '../reason/common/CurrentUser/CurrentUserProvider'
import App from '../pagesComponents/App'
import '../components/NotificationAlert/index.scss'
import smoothscroll from 'smoothscroll-polyfill'
import '../stylesheets/app.scss'
import '../stylesheets/globals.css'
import configureStore from '../store/configureStore'
import rootSaga from '../sagas'
import { loginToken } from '../utils'
import IntercomHandler from '../IntercomSessionHandler'
import QueryParamsProvider from '../components/QueryParamsProvider'
import { make as PathHistoryProvider } from '../reason/PathHistory.bs'
import PageTitle from '../reason/common/PageTitle.bs'
import { RelayEnvironmentProvider } from 'react-relay/hooks'
import { environment as relayEnvironment } from '../RelayEnv.bs'
import { make as SocketStatus } from '../GraphQLWSClient.bs'
import Loader from 'components/Loader'
import { useRouter } from 'next/router'
const { RouterProvider } = require('react-aria-components')

if (typeof window !== 'undefined') {
  smoothscroll.polyfill()
}

import '../reason/clients/components/searchComponents.css'

const store = configureStore()

loginToken.attachToStore(store)
if (typeof window !== 'undefined') {
  store.runSaga(rootSaga)
}

const intercomAppId = process.env.NEXT_PUBLIC_INTERCOM_APP_ID

function MyApp({ Component, pageProps, err }) {
  const getLayout = Component.getLayout || (page => page)
  const page = getLayout(<Component {...pageProps} err={err} />)
  let router = useRouter()

  return (
    <ReduxProvider store={store}>
      <PageTitle title="TAN: The only online network built exclusively for verified top agents" />
      <IntercomProvider appId={intercomAppId}>
        <IntercomHandler />

        <CurrentUserProvider>
          <QueryParamsProvider>
            <PathHistoryProvider>
              <RelayEnvironmentProvider environment={relayEnvironment}>
                <RouterProvider
                  navigate={(href, opts) => {
                    router.push(href, undefined, opts)
                  }}
                  useHref={href => router.basePath + href}
                >
                  <React.Suspense fallback={<Loader />}>
                    <SocketStatus />
                    <App>{page}</App>
                  </React.Suspense>
                </RouterProvider>
              </RelayEnvironmentProvider>
            </PathHistoryProvider>
          </QueryParamsProvider>
        </CurrentUserProvider>
      </IntercomProvider>
    </ReduxProvider>
  )
}

export default MyApp
