// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var TanDate = require("./TanDate.bs.js");
var DateFns = require("date-fns");
var DateFnsTz = require("date-fns-tz");

function now() {
  return new Date(Date.now());
}

function toUnzonedDate(v) {
  return v;
}

function fromUnzonedDate(v) {
  return v;
}

function toZonedDate(prim0, prim1) {
  return DateFnsTz.utcToZonedTime(prim0, prim1);
}

function fromZonedDate(prim0, prim1) {
  return DateFnsTz.zonedTimeToUtc(prim0, prim1);
}

function encodeAsISOString(prim) {
  return prim.toISOString();
}

function fromString(prim) {
  return new Date(prim);
}

function asUTCZonedDate(v) {
  return DateFns.addMinutes(v, v.getTimezoneOffset());
}

function formatDistanceFromNow(trimAboutOpt, date) {
  var trimAbout = trimAboutOpt !== undefined ? trimAboutOpt : false;
  var r = DateFns.formatDistance(date, TanDate.now());
  if (trimAbout) {
    return r.replace("about", "");
  } else {
    return r;
  }
}

function isAfter(t1, t2) {
  return DateFns.isAfter(t1, t2);
}

var decode = TanDate.decode;

var parseFromFloat = TanDate.fromFloat;

exports.now = now;
exports.decode = decode;
exports.parseFromFloat = parseFromFloat;
exports.toUnzonedDate = toUnzonedDate;
exports.fromUnzonedDate = fromUnzonedDate;
exports.toZonedDate = toZonedDate;
exports.fromZonedDate = fromZonedDate;
exports.encodeAsISOString = encodeAsISOString;
exports.fromString = fromString;
exports.asUTCZonedDate = asUTCZonedDate;
exports.formatDistanceFromNow = formatDistanceFromNow;
exports.isAfter = isAfter;
/* date-fns Not a pure module */
