// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Next = require("./next/Next.bs.js");
var React = require("react");
var JsxRuntime = require("react/jsx-runtime");

var context = React.createContext(/* [] */0);

var make = context.Provider;

var Provider = {
  make: make
};

var Context = {
  context: context,
  Provider: Provider
};

function PathHistory(props) {
  var router = Next.useRouter();
  var path = Next.Router.asPath(router);
  var match = React.useState(function () {
        return /* [] */0;
      });
  var updateState = match[1];
  var state = match[0];
  React.useEffect((function () {
          updateState(function (state) {
                return {
                        hd: path,
                        tl: state
                      };
              });
        }), [path]);
  var value = state && state.hd !== path ? ({
        hd: path,
        tl: state
      }) : state;
  return JsxRuntime.jsx(make, {
              value: value,
              children: props.children
            });
}

var make$1 = PathHistory;

exports.Context = Context;
exports.make = make$1;
/* context Not a pure module */
