// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var variablesConverter = {};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, undefined, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: undefined,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "keys"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "keys",
    "variableName": "keys"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flagKey",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "variantKey",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeaturesV3_EvaluateFlagsV5Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EvaluationV5",
        "kind": "LinkedField",
        "name": "evaluateFlagsV5",
        "plural": true,
        "selections": [
          {
            "kind": "RequiredField",
            "field": (v2/*: any*/),
            "action": "NONE"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeaturesV3_EvaluateFlagsV5Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EvaluationV5",
        "kind": "LinkedField",
        "name": "evaluateFlagsV5",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e233eb61efb447d846e3a9a528de247",
    "id": null,
    "metadata": {},
    "name": "FeaturesV3_EvaluateFlagsV5Mutation",
    "operationKind": "mutation",
    "text": "mutation FeaturesV3_EvaluateFlagsV5Mutation(\n  $keys: [String!]!\n) {\n  evaluateFlagsV5(keys: $keys) {\n    flagKey\n    variantKey\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
