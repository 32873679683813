import * as Sentry from '@sentry/nextjs'
import { all, call, cancel, fork, put, race, spawn, take, takeEvery } from 'redux-saga/effects'

import { delay } from 'redux-saga'
import * as api from '../api'
import { environment as relayEnvironment } from '../RelayEnv.bs'
import { commitLocalUpdate } from 'react-relay'

function invalidateRelayStore() {
  return commitLocalUpdate(relayEnvironment, store => {
    store.invalidateStore()
  })
}

import {
  INIT,
  INIT_COMPLETE,
  CREATE_SESSION_REQUEST,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_ERROR,
  EXTERNAL_CREATE_SESSION_SUCCESS,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_ERROR,
  REASON_LOGIN_SUCCESS,
} from '../constants/actionTypes'

const renewSession = (() => {
  const threshold = 300 // 5 minutes
  let lastTask

  return function* renewSessionGenerator(expiresIn, renewFn) {
    if (lastTask) {
      yield cancel(lastTask)
    }

    lastTask = yield fork(function* lastTaskGenerator() {
      const { timeout } = yield race({
        timeout: call(delay, (expiresIn - threshold) * 1000),
        logout: take(LOGOUT),
      })

      if (timeout) {
        yield spawn(renewFn)
      }
    })
  }
})()

function* setupSession(result, createSessionFn) {
  const expiresAt = Date.now() + result.expiresIn * 1000

  yield put({
    type: CREATE_SESSION_SUCCESS,
    data: {
      ...result,
      expiresAt,
      user: { ...result.user, impersonated: result.impersonated ?? false },
    },
  })

  yield spawn(renewSession, result.expiresIn, createSessionFn)
  Sentry.setUser({ id: result.user.id, email: result.user.email })
}

function* createSession() {
  yield put({ type: CREATE_SESSION_REQUEST })

  try {
    const result = yield call(api.createSession)

    yield* setupSession(result, createSession)
  } catch (e) {
    yield put({
      type: CREATE_SESSION_ERROR,
      error: e,
    })
  }
}

function* init() {
  yield call(createSession)
  yield put({ type: INIT_COMPLETE })
}

function* login(action) {
  const { payload } = action

  yield put({ ...action, type: LOGIN_REQUEST })

  try {
    const result = yield call(api.login, payload.username, payload.password, payload.rememberMe)
    const expiresAt = Date.now() + result.expiresIn * 1000
    yield invalidateRelayStore()
    yield put({
      type: LOGIN_SUCCESS,
      data: {
        ...result,
        expiresAt,
        user: result.user,
      },
    })
    Sentry.setUser({ id: result.user.id, email: result.user.email })

    yield spawn(renewSession, result.expiresIn, createSession)
  } catch (e) {
    yield put({
      type: LOGIN_ERROR,
      error: e,
      meta: {},
    })
  }
}

function* logout(action) {
  try {
    yield put({ ...action, type: LOGOUT_REQUEST })

    const result = yield call(api.logout)
    yield invalidateRelayStore()
    yield Sentry.setUser({})

    yield put({
      type: LOGOUT_SUCCESS,
      data: result,
    })
  } catch (e) {
    yield put({
      type: LOGOUT_ERROR,
      error: e,
    })
  }
}

// Used by ReasonComponents
function* reasonLoginSuccess(action) {
  const { payload } = action
  const expiresAt = Date.now() + payload.expiresIn * 1000

  yield invalidateRelayStore()
  yield put({
    type: LOGIN_SUCCESS,
    data: {
      ...payload,
      expiresAt,
      user: payload.user,
    },
  })
  Sentry.setUser({ id: payload.user.id, email: payload.user.email })

  yield spawn(renewSession, payload.expiresIn, createSession)
}

function* externalCreateSessionSuccess(action) {
  const { payload } = action

  yield* setupSession(payload, createSession)
}

// Watch functions

function* watchInit() {
  yield takeEvery(INIT, init)
}

function* watchLogin() {
  yield takeEvery(LOGIN, login)
}

function* watchLogout() {
  yield takeEvery(LOGOUT, logout)
}

function* watchReasonLoginSuccess() {
  yield takeEvery(REASON_LOGIN_SUCCESS, reasonLoginSuccess)
}

function* watchExternalCreateSessionSuccess() {
  yield takeEvery(EXTERNAL_CREATE_SESSION_SUCCESS, externalCreateSessionSuccess)
}

export default function* auth() {
  yield all([
    watchInit(),
    watchExternalCreateSessionSuccess(),
    watchLogin(),
    watchLogout(),
    watchReasonLoginSuccess(),
  ])
}
