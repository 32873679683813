// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Env = require("../reason/common/Env.bs.js");
var AttachmentUrlJs = require("./attachmentUrl.js").default;

function internal_makeAttachmentUrl(prim) {
  return AttachmentUrlJs(prim);
}

function makeAttachmentUrl(width, height, crop, embed, ratio, background, key) {
  return AttachmentUrlJs({
              key: key,
              width: width,
              height: height,
              crop: crop,
              embed: embed,
              ratio: ratio,
              background: background
            });
}

function baseAttachmentUrl(key) {
  return Env.attachment_prefix + ("/" + encodeURIComponent(key));
}

exports.internal_makeAttachmentUrl = internal_makeAttachmentUrl;
exports.makeAttachmentUrl = makeAttachmentUrl;
exports.baseAttachmentUrl = baseAttachmentUrl;
/* Env Not a pure module */
