// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Link = require("next/link").default;
var Icon__Jsx3 = require("../Icon/Icon__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactRender = require("../../../../reason/utils/ReactRender.bs.js");
var FocusVisible = require("../../helpers/FocusVisible.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Theme__Color__Vars = require("../../../../reason/common/Theme/Theme__Color__Vars.bs.js");
var VisuallyHidden = require("@reach/visually-hidden").default;

var container = Css.style({
      hd: Css.display("inlineFlex"),
      tl: {
        hd: Css.justifyContent("center"),
        tl: {
          hd: Css.alignItems("center"),
          tl: {
            hd: Css.border({
                  NAME: "px",
                  VAL: 1
                }, "solid", Theme__Color__Vars.alto),
            tl: {
              hd: Css.borderRadius({
                    NAME: "px",
                    VAL: 4
                  }),
              tl: {
                hd: Css.position("relative"),
                tl: {
                  hd: Css.backgroundColor(Theme__Color__Vars.white),
                  tl: /* [] */0
                }
              }
            }
          }
        }
      }
    });

var link = Css.style({
      hd: Css.width({
            NAME: "px",
            VAL: 30
          }),
      tl: {
        hd: Css.height({
              NAME: "px",
              VAL: 30
            }),
        tl: {
          hd: Css.flexShrink(0),
          tl: {
            hd: Css.display("flex"),
            tl: {
              hd: Css.justifyContent("center"),
              tl: {
                hd: Css.alignItems("center"),
                tl: {
                  hd: Css.position("relative"),
                  tl: {
                    hd: Css.firstChild({
                          hd: Css.after({
                                hd: Css.height({
                                      NAME: "percent",
                                      VAL: 80
                                    }),
                                tl: {
                                  hd: Css.contentRule({
                                        NAME: "text",
                                        VAL: "\" \""
                                      }),
                                  tl: {
                                    hd: Css.width({
                                          NAME: "px",
                                          VAL: 1
                                        }),
                                    tl: {
                                      hd: Css.position("absolute"),
                                      tl: {
                                        hd: Css.right("zero"),
                                        tl: {
                                          hd: Css.backgroundColor(Theme__Color__Vars.alto),
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }),
                          tl: /* [] */0
                        }),
                    tl: {
                      hd: Css.child("span", {
                            hd: Css.display("flex"),
                            tl: {
                              hd: Css.selector("svg", {
                                    hd: Css.color(Theme__Color__Vars.doveGray),
                                    tl: /* [] */0
                                  }),
                              tl: /* [] */0
                            }
                          }),
                      tl: {
                        hd: Css.selector("&[aria-disabled] svg", {
                              hd: Css.opacity(0.3),
                              tl: /* [] */0
                            }),
                        tl: FocusVisible.focusStyle
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var pageNumber = Css.style({
      hd: Css.height({
            NAME: "px",
            VAL: 30
          }),
      tl: {
        hd: Css.flexShrink(0),
        tl: {
          hd: Css.display("flex"),
          tl: {
            hd: Css.justifyContent("center"),
            tl: {
              hd: Css.alignItems("center"),
              tl: {
                hd: Css.position("relative"),
                tl: {
                  hd: Css.padding2("zero", {
                        NAME: "px",
                        VAL: 15
                      }),
                  tl: {
                    hd: Css.color(Theme__Color__Vars.doveGray),
                    tl: {
                      hd: Css.after({
                            hd: Css.height({
                                  NAME: "percent",
                                  VAL: 80
                                }),
                            tl: {
                              hd: Css.contentRule({
                                    NAME: "text",
                                    VAL: "\" \""
                                  }),
                              tl: {
                                hd: Css.width({
                                      NAME: "px",
                                      VAL: 1
                                    }),
                                tl: {
                                  hd: Css.position("absolute"),
                                  tl: {
                                    hd: Css.right("zero"),
                                    tl: {
                                      hd: Css.backgroundColor(Theme__Color__Vars.alto),
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }),
                      tl: /* [] */0
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var Styles = {
  container: container,
  link: link,
  pageNumber: pageNumber
};

function UIKit__PaginationBox$NextPrevLink(props) {
  var children = props.children;
  var href = props.href;
  var match = FocusVisible.useFocusVisible(undefined);
  if (href !== undefined) {
    return JsxRuntime.jsx(Link, {
                href: href,
                passHref: true,
                className: Css.merge({
                      hd: match.className,
                      tl: {
                        hd: link,
                        tl: /* [] */0
                      }
                    }),
                onFocus: match.onFocus,
                onBlur: match.onBlur,
                children: Caml_option.some(children)
              });
  } else {
    return JsxRuntime.jsx("div", {
                children: children,
                "aria-disabled": true,
                className: link
              });
  }
}

var NextPrevLink = {
  make: UIKit__PaginationBox$NextPrevLink
};

function UIKit__PaginationBox(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs(UIKit__PaginationBox$NextPrevLink, {
                      href: props.previousPagePath,
                      children: [
                        JsxRuntime.jsx(VisuallyHidden, {
                              children: "Previous page"
                            }),
                        JsxRuntime.jsx("span", {
                              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                                    icon: "ArrowLeft",
                                    size: 15
                                  }),
                              "aria-hidden": true
                            })
                      ]
                    }),
                ReactRender.fromOption(props.pageInfo, (function (param) {
                        return JsxRuntime.jsx("div", {
                                    children: "Page " + param.currentPage.toString() + " of " + param.totalPages.toString(),
                                    className: pageNumber
                                  });
                      })),
                JsxRuntime.jsxs(UIKit__PaginationBox$NextPrevLink, {
                      href: props.nextPagePath,
                      children: [
                        JsxRuntime.jsx(VisuallyHidden, {
                              children: "Next page"
                            }),
                        JsxRuntime.jsx("span", {
                              children: JsxRuntime.jsx(Icon__Jsx3.make, {
                                    icon: "ArrowRight",
                                    size: 15
                                  }),
                              "aria-hidden": true
                            })
                      ]
                    })
              ],
              className: container
            });
}

var make = UIKit__PaginationBox;

var $$default = UIKit__PaginationBox;

exports.Styles = Styles;
exports.NextPrevLink = NextPrevLink;
exports.make = make;
exports.default = $$default;
exports.__esModule = true;
/* container Not a pure module */
