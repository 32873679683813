// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Env = require("./reason/common/Env.bs.js");
var React = require("react");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var GraphqlWs = require("graphql-ws");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ReactUpdate = require("rescript-react-update/src/ReactUpdate.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Buttons__Jsx3 = require("./uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var SystemMessages = require("./reason/systemMessages/SystemMessages.bs.js");
var Utils__LoginToken = require("./utils/Utils__LoginToken.bs.js");
var JsxRuntime = require("react/jsx-runtime");

var WSClientConfig = {
  numberOfRetries: 5,
  showErrorAfterRetries: 2,
  keepAliveMs: 10000,
  timeoutInterval: 2000
};

var timeout = {
  contents: undefined
};

var queueTimout = {
  contents: (function () {
      
    })
};

var $$clearTimeout = {
  contents: (function () {
      
    })
};

function useSubscriptionErrorDisplay() {
  var subscriptionErrorSystemMessage = React.useRef(undefined);
  var match = SystemMessages.useSystemMessages();
  var messages = match.messages;
  var removeMessage = match.removeMessage;
  var addMessage = match.addMessage;
  React.useEffect((function () {
          return (function () {
                    var x = subscriptionErrorSystemMessage.current;
                    if (x !== undefined) {
                      return removeMessage(x);
                    }
                    
                  });
        }), []);
  var messageId = "subscription-error";
  var show = function () {
    if (!Core__List.some(messages, (function (param) {
              return param.id === messageId;
            }))) {
      return addMessage(SystemMessages.Message.make(messageId, "error", "There was a connection error. Please try refreshing...", 0, (function (param) {
                        return JsxRuntime.jsx(Buttons__Jsx3.OutlineButton.make, {
                                    onClick: (function (param) {
                                        window.location.reload();
                                      }),
                                    children: "Refresh"
                                  });
                      }), false));
    }
    
  };
  var hide = function () {
    removeMessage(messageId);
  };
  return [
          show,
          hide
        ];
}

var dispatch = {
  contents: (function (param) {
      
    })
};

var initialValue = {
  status: "Closed",
  failedConnectionAttempts: 0
};

function useDisplaySubscriptionConnectionErrors() {
  var match = ReactUpdate.useReducer((function (state, action) {
          switch (state.status) {
            case "Closed" :
                switch (action) {
                  case "Closed" :
                      return "NoUpdate";
                  case "Connecting" :
                      return {
                              TAG: "Update",
                              _0: {
                                status: "Connecting",
                                failedConnectionAttempts: state.failedConnectionAttempts
                              }
                            };
                  case "Connected" :
                      return {
                              TAG: "Update",
                              _0: {
                                status: "Connected",
                                failedConnectionAttempts: 0
                              }
                            };
                  
                }
            case "Connecting" :
                switch (action) {
                  case "Closed" :
                      return {
                              TAG: "Update",
                              _0: {
                                status: "Closed",
                                failedConnectionAttempts: state.failedConnectionAttempts + 1 | 0
                              }
                            };
                  case "Connecting" :
                      return "NoUpdate";
                  case "Connected" :
                      return {
                              TAG: "Update",
                              _0: {
                                status: "Connected",
                                failedConnectionAttempts: 0
                              }
                            };
                  
                }
            case "Connected" :
                switch (action) {
                  case "Closed" :
                      return {
                              TAG: "Update",
                              _0: {
                                status: "Closed",
                                failedConnectionAttempts: state.failedConnectionAttempts
                              }
                            };
                  case "Connecting" :
                  case "Connected" :
                      return "NoUpdate";
                  
                }
            
          }
        }), initialValue);
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          dispatch.contents = send;
          return (function () {
                    dispatch.contents = (function (param) {
                        
                      });
                  });
        }), []);
  var match$1 = useSubscriptionErrorDisplay();
  var hide = match$1[1];
  var show = match$1[0];
  React.useEffect((function () {
          if (state.failedConnectionAttempts > 2) {
            show();
          } else {
            hide();
          }
        }), [state.failedConnectionAttempts]);
}

function GraphQLWSClient$SocketStatus(props) {
  useDisplaySubscriptionConnectionErrors();
  return null;
}

var SocketStatus = {
  initialValue: initialValue,
  useDisplaySubscriptionConnectionErrors: useDisplaySubscriptionConnectionErrors,
  make: GraphQLWSClient$SocketStatus
};

var wsStateUpdateListeners = [];

function notifyWsStateUpdateListeners(status) {
  wsStateUpdateListeners.forEach(function (listener) {
        listener(status);
      });
}

function addWsStateUpdateListener(listener) {
  wsStateUpdateListeners.push(listener);
  return function () {
    wsStateUpdateListeners.splice(wsStateUpdateListeners.indexOf(listener), 1);
  };
}

function useOnWsStatusUpdated(cb) {
  React.useEffect((function () {
          return addWsStateUpdateListener(cb);
        }), [cb]);
}

var match = typeof window === "undefined" ? undefined : window;

var client = match !== undefined ? Caml_option.some(GraphqlWs.createClient({
            url: Env.graphqlWsHost + "/ws/graphql",
            shouldRetry: (function (param) {
                return true;
              }),
            keepAlive: 10000,
            connectionParams: (function () {
                return Utils__LoginToken.getWithExpiryCheck().then(function (token) {
                            return Promise.resolve(Object.fromEntries(Core__Option.getOr(Core__Option.map(token, (function (t) {
                                                      return [[
                                                                "token",
                                                                t
                                                              ]];
                                                    })), [])));
                          });
              }),
            on: {
              closed: (function (param) {
                  dispatch.contents("Closed");
                  notifyWsStateUpdateListeners("Closed");
                }),
              connected: (function (param, param$1) {
                  dispatch.contents("Connected");
                  notifyWsStateUpdateListeners("Connected");
                }),
              connecting: (function () {
                  dispatch.contents("Connecting");
                  notifyWsStateUpdateListeners("Connecting");
                }),
              ping: (function (received, param) {
                  if (!received) {
                    return queueTimout.contents();
                  }
                  
                }),
              pong: (function (received, param) {
                  if (received) {
                    return $$clearTimeout.contents();
                  }
                  
                })
            },
            retryAttempts: 5
          })) : undefined;

queueTimout.contents = (function () {
    if (client === undefined) {
      return ;
    }
    var client$1 = Caml_option.valFromOption(client);
    var timeoutId = setTimeout((function () {
            client$1.terminate();
          }), 2000);
    timeout.contents = Caml_option.some(timeoutId);
  });

$$clearTimeout.contents = (function () {
    var timeoutId = timeout.contents;
    if (timeoutId !== undefined) {
      clearTimeout(Caml_option.valFromOption(timeoutId));
      return ;
    }
    
  });

var make = GraphQLWSClient$SocketStatus;

exports.WSClientConfig = WSClientConfig;
exports.timeout = timeout;
exports.queueTimout = queueTimout;
exports.$$clearTimeout = $$clearTimeout;
exports.useSubscriptionErrorDisplay = useSubscriptionErrorDisplay;
exports.dispatch = dispatch;
exports.SocketStatus = SocketStatus;
exports.make = make;
exports.wsStateUpdateListeners = wsStateUpdateListeners;
exports.notifyWsStateUpdateListeners = notifyWsStateUpdateListeners;
exports.addWsStateUpdateListener = addWsStateUpdateListener;
exports.useOnWsStatusUpdated = useOnWsStatusUpdated;
exports.client = client;
/* match Not a pure module */
