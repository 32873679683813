// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Array = require("@rescript/core/src/Core__Array.bs.js");
var Core__Float = require("@rescript/core/src/Core__Float.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Result = require("@rescript/core/src/Core__Result.bs.js");
var Utils__AttachmentUrl = require("./Utils__AttachmentUrl.bs.js");

function uniq(input, fn) {
  return Core__List.reduce(input, /* [] */0, (function (newList, value) {
                if (Core__List.some(newList, (function (a) {
                          return fn(a) === fn(value);
                        }))) {
                  return newList;
                } else {
                  return {
                          hd: value,
                          tl: newList
                        };
                }
              }));
}

function join(separator, lastSeparatorOpt, list) {
  var lastSeparator = lastSeparatorOpt !== undefined ? lastSeparatorOpt : separator;
  return Core__List.reduceWithIndex(list, "", (function (acc, x, i) {
                if (i !== 0) {
                  if (i === (Core__List.length(list) - 1 | 0)) {
                    return acc + (lastSeparator + x);
                  } else {
                    return acc + (separator + x);
                  }
                } else {
                  return x;
                }
              }));
}

function take(n, xs) {
  if (!xs) {
    return /* [] */0;
  }
  var hd = xs.hd;
  if (n === 1) {
    return {
            hd: hd,
            tl: /* [] */0
          };
  } else {
    return {
            hd: hd,
            tl: take(n - 1 | 0, xs.tl)
          };
  }
}

function drop(_n, _xs) {
  while(true) {
    var xs = _xs;
    var n = _n;
    if (!xs) {
      return /* [] */0;
    }
    if (n <= 0) {
      return xs;
    }
    _xs = xs.tl;
    _n = n - 1 | 0;
    continue ;
  };
}

function chunks_of(n, l) {
  if (l && n > 0) {
    return {
            hd: take(n, l),
            tl: chunks_of(n, drop(n, l))
          };
  } else {
    return /* [] */0;
  }
}

function findFirst(predicate, _xs) {
  while(true) {
    var xs = _xs;
    if (!xs) {
      return ;
    }
    var hd = xs.hd;
    if (predicate(hd)) {
      return Caml_option.some(hd);
    }
    _xs = xs.tl;
    continue ;
  };
}

function pseudoJoin(joiner, lastJoiner, xs) {
  if (!xs) {
    return /* [] */0;
  }
  var tail = xs.tl;
  var hd = xs.hd;
  if (tail) {
    if (tail.tl) {
      return {
              hd: hd,
              tl: {
                hd: joiner,
                tl: pseudoJoin(joiner, lastJoiner, tail)
              }
            };
    } else {
      return {
              hd: hd,
              tl: {
                hd: Core__Option.getOr(lastJoiner, joiner),
                tl: {
                  hd: tail.hd,
                  tl: /* [] */0
                }
              }
            };
    }
  } else {
    return {
            hd: hd,
            tl: /* [] */0
          };
  }
}

function stripNones(x) {
  return Core__List.filterMap(x, (function (x) {
                return x;
              }));
}

function any(predicate, lst) {
  return Core__Option.isSome(findFirst(predicate, lst));
}

function all(predicate, lst) {
  return !any((function (v) {
                return !predicate(v);
              }), lst);
}

function replace(index, newElement, list) {
  if (!list) {
    return list;
  }
  var tail = list.tl;
  if (index === 0) {
    return {
            hd: newElement,
            tl: tail
          };
  } else {
    return {
            hd: list.hd,
            tl: replace(index - 1 | 0, newElement, tail)
          };
  }
}

function replaceFirst(predicate, newElement, list) {
  if (!list) {
    return list;
  }
  var tail = list.tl;
  var hd = list.hd;
  if (predicate(hd)) {
    return {
            hd: newElement,
            tl: tail
          };
  } else {
    return {
            hd: hd,
            tl: replaceFirst(predicate, newElement, tail)
          };
  }
}

function remove(index, list) {
  if (!list) {
    return list;
  }
  var tail = list.tl;
  if (index === 0) {
    return tail;
  } else {
    return {
            hd: list.hd,
            tl: remove(index - 1 | 0, tail)
          };
  }
}

function last(_list) {
  while(true) {
    var list = _list;
    if (!list) {
      return ;
    }
    var rest = list.tl;
    if (!rest) {
      return Caml_option.some(list.hd);
    }
    _list = rest;
    continue ;
  };
}

function isEmpty(x) {
  if (x) {
    return false;
  } else {
    return true;
  }
}

var List = {
  uniq: uniq,
  join: join,
  take: take,
  drop: drop,
  chunks_of: chunks_of,
  findFirst: findFirst,
  pseudoJoin: pseudoJoin,
  stripNones: stripNones,
  any: any,
  all: all,
  replace: replace,
  replaceFirst: replaceFirst,
  remove: remove,
  last: last,
  isEmpty: isEmpty
};

function uniq$1(input, fn) {
  return Core__Array.reduce(input, [], (function (newArr, value) {
                if (newArr.some(function (a) {
                        return fn(a) === fn(value);
                      })) {
                  return newArr;
                } else {
                  return [value].concat(newArr);
                }
              }));
}

function pseudoJoin$1(joiner, lastJoiner, arr) {
  return Core__List.toArray((function (__x) {
                  return pseudoJoin(joiner, lastJoiner, __x);
                })(Core__List.fromArray(arr)));
}

function joinWith(joinStr, arr) {
  return arr.join(joinStr);
}

function joinWithLabels(separator, arr) {
  return arr.join(separator);
}

function sort(cmp, arr) {
  var cp = arr.slice();
  if (cmp !== undefined) {
    ((function (__x) {
            $$Array.sort(cmp, __x);
          })(cp));
  } else {
    ((function (__x) {
            $$Array.sort(Caml_obj.compare, __x);
          })(cp));
  }
  return cp;
}

function stripNones$1(x) {
  return Core__Array.filterMap(x, (function (x) {
                return x;
              }));
}

function deleteAtIndex(i, arr) {
  return arr.filter(function (param, i$p) {
              return i !== i$p;
            });
}

function join$1(separator, lastSeparatorOpt, list) {
  var lastSeparator = lastSeparatorOpt !== undefined ? lastSeparatorOpt : separator;
  return Core__Array.reduceWithIndex(list, "", (function (acc, x, i) {
                if (i !== 0) {
                  if (i === (list.length - 1 | 0)) {
                    return acc + (lastSeparator + x);
                  } else {
                    return acc + (separator + x);
                  }
                } else {
                  return x;
                }
              }));
}

var $$Array$1 = {
  uniq: uniq$1,
  pseudoJoin: pseudoJoin$1,
  joinWith: joinWith,
  joinWithLabels: joinWithLabels,
  sort: sort,
  stripNones: stripNones$1,
  deleteAtIndex: deleteAtIndex,
  join: join$1
};

function get(i, x) {
  var x$1;
  try {
    x$1 = x[i];
  }
  catch (exn){
    return ;
  }
  return Caml_option.some(x$1);
}

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.substring(1);
}

var nbsp = '\u00a0';

var upperHalfBlock = '\u2580';

var lowerHalfBlock = '\u2584';

var lastCommaRe = /,(?!.*,)/gmi;

function enumerate(strings) {
  return Core__List.toArray(strings).join(", ").replace(lastCommaRe, " and");
}

function formatAsPhone(value) {
  var value$1 = value.replace(/\D/g, "");
  if (value$1.length === 0) {
    return "";
  }
  var first = value$1.substring(0, 3);
  var middle = value$1.substring(3, 6);
  var last = value$1.substring(6, 10);
  if (value$1.length > 6) {
    return "(" + first + ") " + middle + " - " + last;
  } else if (value$1.length > 3) {
    return "(" + first + ") " + middle;
  } else {
    return "(" + first;
  }
}

var $$String = {
  get: get,
  capitalize: capitalize,
  nbsp: nbsp,
  upperHalfBlock: upperHalfBlock,
  lowerHalfBlock: lowerHalfBlock,
  lastCommaRe: lastCommaRe,
  enumerate: enumerate,
  formatAsPhone: formatAsPhone
};

function ofOption(error, opt) {
  if (opt !== undefined) {
    return {
            TAG: "Ok",
            _0: Caml_option.valFromOption(opt)
          };
  } else {
    return {
            TAG: "Error",
            _0: error
          };
  }
}

function ofArray(error, opt) {
  if (opt.length !== 0) {
    return {
            TAG: "Ok",
            _0: opt
          };
  } else {
    return {
            TAG: "Error",
            _0: error
          };
  }
}

function toOption(x) {
  if (x.TAG === "Ok") {
    return Caml_option.some(x._0);
  }
  
}

function map(ok, error, result) {
  if (result.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: ok(result._0)
          };
  } else {
    return {
            TAG: "Error",
            _0: error(result._0)
          };
  }
}

function mapOk(ok, result) {
  return map(ok, (function (i) {
                return i;
              }), result);
}

function mapError(error, result) {
  return map((function (i) {
                return i;
              }), error, result);
}

function apFromOption(value, msg, fn) {
  return Core__Result.map(ofOption(msg, value), (function (x) {
                return fn(x);
              }));
}

function apFromArray(value, msg, fn) {
  return Core__Result.map(ofArray(msg, value), (function (x) {
                return fn(x);
              }));
}

function apFromResult(value, fn) {
  return Core__Result.map(value, (function (x) {
                return fn(x);
              }));
}

var Result = {
  ofOption: ofOption,
  ofArray: ofArray,
  toOption: toOption,
  map: map,
  mapOk: mapOk,
  mapError: mapError,
  apFromOption: apFromOption,
  apFromArray: apFromArray,
  apFromResult: apFromResult
};

var UUID = {};

function fromString(s) {
  if (isFinite(s)) {
    return Core__Float.fromString(s);
  }
  
}

function isFloat(s) {
  return Core__Option.isSome(fromString(s));
}

function isPositive(s) {
  return Core__Option.getOr(Core__Option.map(fromString(s), (function (x) {
                    return x >= 0.0;
                  })), false);
}

function toTruncatedString(f) {
  if (f % 1 === 0) {
    return String(f | 0);
  } else {
    return f.toString();
  }
}

var Float = {
  fromString: fromString,
  isFloat: isFloat,
  isPositive: isPositive,
  toTruncatedString: toTruncatedString
};

function setCurrentRef(reactRef, value) {
  reactRef.current = value;
}

var React = {
  setCurrentRef: setCurrentRef
};

function throttle(f, t) {
  var pending = {
    contents: undefined
  };
  return function (v) {
    Core__Option.map(pending.contents, (function (prim) {
            clearTimeout(prim);
          }));
    pending.contents = Caml_option.some(setTimeout((function () {
                f(v);
              }), t));
  };
}

var Throttle = {
  throttle: throttle
};

var makeAttachmentUrl = Utils__AttachmentUrl.makeAttachmentUrl;

var baseAttachmentUrl = Utils__AttachmentUrl.baseAttachmentUrl;

var $$Option;

var $$Promise;

var Regex;

var LoginToken;

exports.makeAttachmentUrl = makeAttachmentUrl;
exports.baseAttachmentUrl = baseAttachmentUrl;
exports.List = List;
exports.$$Array = $$Array$1;
exports.$$String = $$String;
exports.Result = Result;
exports.UUID = UUID;
exports.$$Option = $$Option;
exports.Float = Float;
exports.React = React;
exports.Throttle = Throttle;
exports.$$Promise = $$Promise;
exports.Regex = Regex;
exports.LoginToken = LoginToken;
/* Utils__AttachmentUrl Not a pure module */
