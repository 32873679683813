// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Env = require("./common/Env.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Nextjs = require("@sentry/nextjs");

function errorLevelToJs(level) {
  return level;
}

function addBreadcrumb(message, level, category) {
  Nextjs.addBreadcrumb({
        message: message,
        category: category,
        level: Core__Option.map(level, errorLevelToJs)
      });
}

function captureException(exn, file, line, extra, param) {
  if (Env.node_env === "development") {
    console.log("Sentry.captureException", {
          exn: exn,
          extra: extra,
          file: file,
          line: line
        });
  }
  Nextjs.withScope(function (scope) {
        if (extra !== undefined) {
          scope.setExtra("extra", Caml_option.valFromOption(extra));
        }
        scope.setTag("file", file);
        if (line !== undefined) {
          scope.setTag("line", String(line));
        }
        Nextjs.captureException(exn);
      });
}

function captureErrorMessage(message, userFacing, decoder, file, line, extra, levelOpt, param) {
  var level = levelOpt !== undefined ? levelOpt : "error";
  if (Env.node_env === "development") {
    console.log("Sentry.captureErrorMessage", {
          message: message,
          userFacing: userFacing,
          file: file,
          line: line,
          decoder: decoder,
          extra: extra,
          level: level
        });
  }
  Nextjs.withScope(function (scope) {
        if (extra !== undefined) {
          scope.setExtra("extra", Caml_option.valFromOption(extra));
        }
        if (decoder !== undefined) {
          if (decoder) {
            scope.setTag("decoder-error", "true");
          } else {
            scope.setTag("decoder-error", "false");
          }
        }
        scope.setTag("user-facing", userFacing ? "true" : "false");
        scope.setTag("file", file);
        scope.setTag("line", String(line));
        Nextjs.captureMessage(message, level);
      });
}

exports.errorLevelToJs = errorLevelToJs;
exports.addBreadcrumb = addBreadcrumb;
exports.captureException = captureException;
exports.captureErrorMessage = captureErrorMessage;
/* Env Not a pure module */
